import { Injectable } from '@angular/core';
import { SubscriptionType } from '@app/core/interfaces/subscription-type.model';
import { PaymentTerm } from '@app/core/interfaces/payment-term.model';
import { ApplicationSubscription } from '@app/core/interfaces/subscription.model';
import { MollieSubscription } from '@app/core/interfaces/mollie-subscription.model';
import { SubscriptionTransitions } from '@app/core/interfaces/subscription-transitions.enum';
import { environment } from 'environments/environment';
import { CoreModule } from '../core.module';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GroupNames } from '../interfaces/group-names.enum';
@Injectable({
  providedIn: CoreModule
})
export class SubscriptionService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  public fetchSubscriptionTypes$() {
    return this.http.get<SubscriptionType[]>(this.apiLocation + '/subscription-type')
  }

  public fetchPaymentTerms$() {
    return this.http.get<PaymentTerm[]>(this.apiLocation + '/payment-term');
  }

  public getMollieSubscription(appSubscription: ApplicationSubscription) {
    return this.http.get<MollieSubscription>(this.apiLocation + `/subscription/${appSubscription.id}/mollie-subscription/${appSubscription.mollieSubscriptionId}`);
  }

  public canRequestSubscriptionType(newSubscriptionType: SubscriptionType, currentSubscriptionType: SubscriptionType) {
    let canSubscribe = false;
    if (newSubscriptionType.order > currentSubscriptionType.order) {
      canSubscribe = true;
    }

    return canSubscribe;
  }

  public canRequestPaymentTerm(newPaymentTerm: PaymentTerm, currentPaymentTerm: PaymentTerm): boolean {
    let canRequest = false;

    // If there is no current payment term, you can request the payment term.
    if (currentPaymentTerm === null) {
      canRequest = true;
    }

    /* You can only request paymentterms of a higher order */
    if (newPaymentTerm.order > currentPaymentTerm.order) {
      canRequest = true;
    }

    return canRequest;
  }

  public getSubscriptionPossibilities(
    currentSubscriptionType: SubscriptionType,
    currentPaymentTerm: PaymentTerm,
    availableSubscriptionTypes: SubscriptionType[],
    availablePaymentTerms: PaymentTerm[]
  ) {
    const possibilities = [];

    const possibleSubscriptionTypes = availableSubscriptionTypes.filter(sT => this.canRequestSubscriptionType(sT, currentSubscriptionType));
    possibleSubscriptionTypes.forEach(sT => {
      possibilities.push({
        subscriptionType: sT,
        paymentTerms: availablePaymentTerms
      });
    });

    // Check if there are any different payment options for the current subscription type.
    const possiblePaymentOptionsForCurrentSubscription = availablePaymentTerms.filter(paymentTerm => this.canRequestPaymentTerm(paymentTerm, currentPaymentTerm));
    // Add them to the possibilities.
    if (possiblePaymentOptionsForCurrentSubscription.length !== 0) {
      possibilities.push({
        subscriptionType: currentSubscriptionType,
        paymentTerms: possiblePaymentOptionsForCurrentSubscription
      });
    }
    return possibilities;
  }

  public changePaymentTerm(currentSubscription: ApplicationSubscription, futurePaymentTerm: PaymentTerm) {
    return this.http.put(
      this.apiLocation + `/subscription/${currentSubscription.id}/payment-term/${currentSubscription.paymentTerm.name}`,
      {
        futurePaymentTerm: futurePaymentTerm
      }
    );
  }

  public cancelSubscription(subscriptionId: string) {
    return this.http.delete<void>(this.apiLocation + `/subscription/${subscriptionId}`);
  }

  public setCancellationReason(subscriptionId: string, params: {cancellationReason: string, satisfied: boolean}) {
    return this.http.post<ApplicationSubscription>(this.apiLocation + `/subscription/${subscriptionId}/cancellation-reason`, params);
  }

  public upgradeSubscription(currentSubscription: ApplicationSubscription, subscriptionType: SubscriptionType, paymentTerm: PaymentTerm) {
    return this.http.post(
      this.apiLocation + `/subscription/${currentSubscription.id}/subscription-type`,
      {
        subscriptionType: subscriptionType,
        paymentTerm: paymentTerm
      }
    );
  }

  public determineSubscriptionTransition(
    currentSubscription: ApplicationSubscription,
    newSubscriptionType: SubscriptionType,
    newPaymentTerm: PaymentTerm
  ): SubscriptionTransitions {
    let type: SubscriptionTransitions;
    if (currentSubscription.subscriptionType.name === 'free') {
      // First account creation.
      type = SubscriptionTransitions.Initial;
    } else if (currentSubscription.subscriptionType.name === newSubscriptionType.name && currentSubscription.paymentTerm.order !== newPaymentTerm.order) {
      // Payment period change.
      type = SubscriptionTransitions.PaymentTermChange;
    } else if (currentSubscription.subscriptionType.order < newSubscriptionType.order) {
      // Tier upgrade
      type = SubscriptionTransitions.TierUpgrade;
    } else if (currentSubscription.subscriptionType.order > newSubscriptionType.order) {
      // Tier downgrade -> Shouldnt happen yet.
      type = SubscriptionTransitions.TierDowngrade;
      throw new Error('Subscription transition not implemented yet');
    }
    if (type === undefined) {
      throw new Error('Magical subscription transition found.');
    }

    return type;
  }

  public calculateMonthlySubscriptionPrice(subscriptionType: SubscriptionType, paymentTerm: PaymentTerm): number {
    return subscriptionType.pricePerMonth * paymentTerm?.discount * 1.21;
  }

  public calculateSubscriptionPrice(subscriptionType: SubscriptionType, paymentTerm: PaymentTerm): number {
    return subscriptionType.pricePerMonth * paymentTerm?.discount * paymentTerm?.months * 1.21;
  }

  public getPendingSubscription(subscriptions: ApplicationSubscription[]): ApplicationSubscription {
    const pendingSubscriptionCandidates = subscriptions.filter(s => s.status === 'pending');

    let pendingSubscription;

    if (pendingSubscriptionCandidates.length === 0) {
      pendingSubscription = null;
    } else {
      pendingSubscription = pendingSubscriptionCandidates[0];
    }

    return pendingSubscription;
  }

  public getActiveSubscription(subscriptions: ApplicationSubscription[]): ApplicationSubscription {
    const activeSubscriptionCanditates = subscriptions.filter(s => s.status === 'active');

    let activeSubscription;
    if (activeSubscriptionCanditates.length === 0) {
      activeSubscription = null;
    } else {
      activeSubscription = activeSubscriptionCanditates[0];
    }

    return activeSubscription;
  }




}
